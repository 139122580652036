export default (fuel_type, fallback = "Onbekend") => (
    {
        B: 'Benzine',
        D: 'Diesel',
        L: 'LPG',
        3: 'LPG G3',
        E: 'Elektrisch',
        H: 'Hybride',
        HB: 'Hybride benzine',
        HD: 'Hybride diesel',
        C: 'CNG',
        O: 'Overig',
    }[fuel_type] || fallback)