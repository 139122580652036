import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';

const OptimizedImage = ({ src, sizes = [], initRatio = null, alt, lazy }) => {
  const set = useMemo(() => {
    const set = [];
    const mObj = src?.match(
      /^https:\/\/(?:d6xud4n5jl0p5\.cloudfront\.net|d7cvm8ak29oyx\.cloudfront\.net|cdn\.autokan\.nl)\/(images\/.+\.(jpe?g|webp))/
    );
    if (!mObj) return;
    const path = mObj[1];
    const types = ['webp', 'jpeg'];
    const factors = [1, 2];
    const ratio = initRatio ?? 240 / 320;

    types.forEach((type) => {
      sizes.forEach(([media, width]) => {
        const height = width * ratio;
        const srcSet = factors?.map((factor) => {
          const param = new URLSearchParams({
            type: type,
            width: Math.round(width * factor),
            height: Math.round(height * factor),
            cover: 'fill',
          });
          return `https://d6xud4n5jl0p5.cloudfront.net/${path}?${param.toString()} ${factor}x`;
        });
        set.push({
          width: Math.round(width),
          height: Math.round(height),
          mime: `image/${type}`,
          srcSet,
          media,
        });
      });
    });

    return set;
  }, [src, sizes]);

  const imgRef = useRef();
  useEffect(() => {
    // Fixes a bug much like this one: https://github.com/vuejs/vue/issues/11357
    const img = imgRef.current;
    if (!img || !src) return;
    img.setAttribute('src', src);
  }, [imgRef.current, src]);

  return (
    <picture className="img">
      {set?.map(({ width, height, mime, srcSet, media }, ix) => (
        <source
          key={ix}
          media={media}
          srcSet={srcSet.join(', ')}
          type={mime}
          width={width}
          height={height}
        />
      ))}

      <img ref={imgRef} alt={alt} loading={lazy ? 'lazy' : undefined} />
    </picture>
  );
};

OptimizedImage.propTypes = {
  src: PropTypes.string,
  lazy: PropTypes.bool,
  alt: PropTypes.string,
  width: PropTypes.number,
  sizes: PropTypes.arrayOf(PropTypes.any),
  initRatio: PropTypes.number,
};

export default OptimizedImage;
