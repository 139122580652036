
const { format: formatNoDecimals } = (new Intl.NumberFormat('nl-NL', {
    style: "currency",
    currency: "EUR",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
}))
export const currenyNoDecimals = (value) => formatNoDecimals(value);

const { format } = (new Intl.NumberFormat('nl-NL', {
    style: "currency",
    currency: "EUR",
}))
export default (value) => format(value).replace(/,00$/, "");


const { format: formatNoDecimalsOrSymbol } = (new Intl.NumberFormat('nl-NL', {
    style: "currency",
    currency: "EUR",
    currencyDisplay: 'code',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
}))
export const currenyNoDecimalsOrSymbol = (value) => formatNoDecimalsOrSymbol(value).replace(/\s*EUR\s*/, "");
