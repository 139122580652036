import styled from '@emotion/styled';
import { pxToRem } from '../theme/Theme';
import BadgeStyle from './Badge.style';

export default styled.div`
  cursor: pointer;
  min-width: 0;
  position: relative;
  border-radius: 4px;
  border: solid thin ${({ theme }) => theme.colors.quartz};
  padding: 0 15px 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .image-container {
    position: relative;
    grid-area: img;
  }
  .details {
    grid-area: details;
  }
  .offer {
    grid-area: offer;
  }

  .img {
    display: block;
    align-self: stretch;
    height: 260px;
    width: calc(100% + 32px);
    margin: -1px -16px 15px;
    & > img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
      border-radius: 4px 4px 0 0;
      ${({ theme }) => theme.media.laptop} {
        border-radius: 4px 0 0 4px;
      }
    }
  }
  &.small .img > img {
    border-radius: 4px 4px 0 0;
  }
  ${BadgeStyle} {
    position: absolute;
    left: 4px;
    top: 5px;
  }
  .name {
    margin: 8px 0;
  }
  .description {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 8px 0;
  }
  .fill {
    flex: 1;
  }
  .offer {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .price {
      ${({ theme }) => theme.fonts.openSans.bold};
      color: ${({ theme }) => theme.colors.pacificBlue};
      font-size: 22px;
      .interval {
        font-size: 12px;
      }
    }
  }
  ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    list-style: none;
    padding: 0;
    margin: 20px 0;
    gap: 15px 20px;
    li:before {
      content: '\u25CF';
      color: ${({ theme }) => theme.colors.pacificBlue};
      margin-right: 10px;
      font-size: 1.2em;
    }
  }

  ${({ theme }) => theme.media.tablet} {
    &:not(.small) {
      .img {
        height: 360px;
      }
    }
  }
  ${({ theme }) => theme.media.laptop} {
    &:not(.small) {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-template-rows: 1fr auto;
      grid-template-areas: 'img details' 'img offer';
      gap: 0 30px;
      padding: 20px;
      .img {
        height: 240px;
        width: 320px;
        ${({ theme }) => theme.media.desktop} {
          width: 386px;
        }
        margin: 0;
        border-radius: 2px;
      }
      .details {
        overflow: hidden;
        flex: 1;
      }
      .views {
        left: 25px;
        top: 25px;
        font-size: ${pxToRem(12)};
      }
      .description {
        font-size: ${pxToRem(18)};
      }
    }
  }
  &.search {
    &.mobile {
      padding: 15px;
      grid-template-areas:
        'img details'
        'offer offer';
      grid-template-columns: auto 1fr;
      grid-template-rows: auto auto;
      display: grid;
      gap: 17px;
      & > .fill {
        display: none;
      }
      & > .image-container {
        & > .img {
          width: 96px;
          height: 100%;
          margin: 0;
          border-radius: 2px;
        }
      }
      & > .details {
        grid-area: details;
        overflow: hidden;

        & > .name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          /* width: 40px; */
          align-self: stretch;
          /* display: inline-block; */
        }
        & > .description {
          display: none;
        }
        & > ul {
          position: relative;
          display: inline-block;
          padding-left: 20px;
          margin: 5px 0px;
          &::before {
            content: '●';
            color: ${({ theme }) => theme.colors.pacificBlue};
            left: 0;
            position: absolute;
          }
          & > li {
            display: inline;
            &:first-of-type::before {
              display: none;
            }
            &:not(:first-of-type)::before {
              content: ', ';
              margin: 0;
              padding: 0;
              color: inherit;
              font: inherit;
            }
          }
        }
      }
      & > .offer {
        grid-area: offer;
      }
    }
    &.desktop {
      padding: 15px;
      grid-template-areas: 'img details offer';
      grid-template-columns: auto 1fr auto;
      grid-template-rows: 1fr;
      gap: 20px;
      .img {
        width: 147px;
        height: 100%;
      }

      .offer {
        flex-direction: column;
        gap: 18px;
        align-items: flex-start;
      }
      .details {
        .name,
        .description {
          margin: 5px 0;
        }
        ul {
          margin: 15px 0 0;
          gap: 5px 20px;
          font-size: 13px;
        }
      }
      .fill {
        display: none;
      }
    }
  }
`;
