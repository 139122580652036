import styled from '@emotion/styled';

export default styled.div`
  background-color: ${({ theme }) => theme.colors.pacificBlue};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  & > .content {
    padding: 3px 10px 5px;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 0.5em;
    min-height: 32px;
    line-height: inherit;
  }
  &.orange {
    background: linear-gradient(90deg, #ff9f47 0%, #ffb800 100%);
  }
`;
