import { DateTime } from 'luxon';
import fuelType from './fuelType';
import transmission from './transmission';

export const buildTitle = (brand, model) => {
  if (brand && model) {
    if (model.startsWith(brand)) return model;
    return `${brand} ${model}`;
  } else if (model) {
    return model;
  } else {
    return brand;
  }
};

export const getTitle = (vehicle) => {
  return (
    vehicle && buildTitle(`${vehicle.make?.name}`, `${vehicle.model?.name}`)
  );
};

export const getMainProperties = (ad) => [
  {
    type: 'fuelType',
    text: fuelType(ad.fuel_type),
  },
  {
    type: 'mileageFromOdometer',
    value: ad.odometer,
    unit: ad.odometer_unit === 'mi' ? 'SMI' : 'KMT',
  },
  {
    type: 'vehicleTransmission',
    text: transmission(ad.transmission),
  },
  {
    type: 'productionDate',
    value:
      typeof ad.registration_date === 'string'
        ? ad.registration_date
        : DateTime.fromMillis(ad.registration_date * 1000).toISODate(),
  },
];

export const MileageFormat = new Intl.NumberFormat('nl-NL', {});
export const DateFormat = {
  format: (str) =>
    DateTime.fromISO(str).toLocaleString({ year: 'numeric', month: 'short' }),
};
