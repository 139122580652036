import React, { useMemo } from 'react';
import StyledBadge from './Badge.style';
import crc16 from 'crc/crc16';
import Typography from './global/Typography';
import Icon from './global/Icon';

const Star = {
  width: 16,
  height: 15,
  d: 'M8.00009 12.1733L3.29809 14.8053L4.34809 9.51998L0.39209 5.86131L5.74342 5.22665L8.00009 0.333313L10.2568 5.22665L15.6081 5.86131L11.6521 9.51998L12.7021 14.8053L8.00009 12.1733ZM8.00009 10.6453L10.8314 12.23L10.1994 9.04798L12.5814 6.84465L9.35942 6.46265L8.00076 3.51665L6.64142 6.46331L3.41942 6.84465L5.80142 9.04798L5.16942 12.23L8.00009 10.6453Z',
};
const Bang = {
  d: 'M6.99992 13.6666C3.31792 13.6666 0.333252 10.682 0.333252 6.99998C0.333252 3.31798 3.31792 0.333313 6.99992 0.333313C10.6819 0.333313 13.6666 3.31798 13.6666 6.99998C13.6666 10.682 10.6819 13.6666 6.99992 13.6666ZM6.99992 12.3333C8.41441 12.3333 9.77096 11.7714 10.7712 10.7712C11.7713 9.77102 12.3333 8.41447 12.3333 6.99998C12.3333 5.58549 11.7713 4.22894 10.7712 3.22874C9.77096 2.22855 8.41441 1.66665 6.99992 1.66665C5.58543 1.66665 4.22888 2.22855 3.22868 3.22874C2.22849 4.22894 1.66659 5.58549 1.66659 6.99998C1.66659 8.41447 2.22849 9.77102 3.22868 10.7712C4.22888 11.7714 5.58543 12.3333 6.99992 12.3333ZM6.33325 8.99998H7.66659V10.3333H6.33325V8.99998ZM6.33325 3.66665H7.66659V7.66665H6.33325V3.66665Z',
  width: 14,
  height: 14,
};
const Clock = {
  d: 'M6 13.3333C4.76232 13.3333 3.57534 12.8417 2.70017 11.9665C1.825 11.0913 1.33333 9.90433 1.33333 8.66666C1.33333 7.42898 1.825 6.24199 2.70017 5.36683C3.57534 4.49166 4.76232 3.99999 6 3.99999C7.23768 3.99999 8.42466 4.49166 9.29983 5.36683C10.175 6.24199 10.6667 7.42898 10.6667 8.66666C10.6667 9.90433 10.175 11.0913 9.29983 11.9665C8.42466 12.8417 7.23768 13.3333 6 13.3333ZM10.6867 4.92666L11.6333 3.97999C11.3333 3.63999 11.0333 3.33332 10.6933 3.03999L9.74667 3.99999C8.71333 3.15999 7.41333 2.66666 6 2.66666C4.4087 2.66666 2.88258 3.2988 1.75736 4.42402C0.632141 5.54923 0 7.07536 0 8.66666C0 10.258 0.632141 11.7841 1.75736 12.9093C2.88258 14.0345 4.4087 14.6667 6 14.6667C9.33333 14.6667 12 11.98 12 8.66666C12 7.25332 11.5067 5.95332 10.6867 4.92666ZM5.33333 9.33332H6.66667V5.33332H5.33333M8 0.666656H4V1.99999H8V0.666656Z',
  width: 12,
  height: 15,
};
const Twinkle = {
  d: 'M11.3333 0.805332L12.2133 2.454L13.8613 3.33333L12.2133 4.21267L11.3347 5.86L10.4533 4.21333L8.80532 3.33333L10.454 2.45467L11.3333 0.805332ZM7.11132 6.222L10.4447 8L7.11132 9.77733L5.33399 13.1107L3.55599 9.77733L0.222656 8L3.55599 6.222L5.33399 2.88867L7.11132 6.222ZM7.61132 8L6.12666 7.20667L5.33399 5.722L4.54132 7.20733L3.05599 8L4.54132 8.792L5.33399 10.2773L6.12599 8.792L7.61132 8ZM13.1113 10.8887L12.0007 8.80533L10.8893 10.8887L8.80599 12L10.8893 13.1107L12.0007 15.194L13.1113 13.1107L15.1947 12L13.1113 10.8887Z',
  width: 16,
  height: 16,
};
const Trending = {
  d: 'M9.66659 0L11.1933 1.52667L7.93992 4.78L5.27325 2.11333L0.333252 7.06L1.27325 8L5.27325 4L7.93992 6.66667L12.1399 2.47333L13.6666 4V0H9.66659Z',
  width: 14,
  height: 8,
};

const quickDelivery = {
  children: (
    <>
      <Icon {...Clock} />
      Snelle levering
    </>
  ),
};
const bestSeller = {
  children: (
    <>
      <Icon {...Bang} />
      Best-seller
    </>
  ),
};
const popular = {
  children: (
    <>
      <Icon {...Twinkle} />
      Vaak bekeken
    </>
  ),
};
const topChoice = {
  children: (
    <>
      <Icon {...Star} />
      Top keuze
    </>
  ),
  className: 'orange',
};
const trending = {
  children: (
    <>
      <Icon {...Trending} />
      Trending
    </>
  ),
};
const badgeProps = (ad) => {
  const key = `${ad.id}|${ad.make.id}|${ad.model.id}`;
  const badgeId = crc16(key).valueOf() % 16;
  return {
    0: quickDelivery,
    1: bestSeller,
    2: popular,
    3: topChoice,
    4: trending,
    5: quickDelivery,
    6: bestSeller,
    7: popular,
    8: topChoice,
    9: trending,
  }[badgeId];
};

const Badge = ({ ad, ...p }) => {
  const badge = useMemo(() => ad && badgeProps(ad), [ad]);
  if (!badge) return <></>;
  const { className, children } = badge;
  return (
    <StyledBadge
      {...p}
      className={'badge' + (className ? ` ${className}` : '')}
    >
      <Typography variant="controlStrongSmaller" className="content">
        {children}
      </Typography>
    </StyledBadge>
  );
};
export default Badge;
